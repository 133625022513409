import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle2 from "../Particle2";
import Github from "./Github";
import Techstack from "./Techstack";
import lightbulb from "../../Assets/about-min.png";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <div className="particle-style2"> <Particle2 /> </div>
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={7} style={{justifyContent: "center", paddingTop: "20px", paddingBottom: "50px",}}>

            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Developer <strong className="purple">Background</strong>
            </h1>

            <p>
              I am a recent Computer Science graduate of the <a href="https://www.uic.edu/" target="_blank" rel="noreferrer" className="purple"> University of Illinois Chicago </a> (May 2022). During my undergraduate studies, I have gained the tools and resources needed to solidify my understanding of the world as an engineer. I have become adept at utilizing both OOP and lower-level principles with languages such as <b className="purple"> C/C++, C#, Java, and Ruby, </b> as well as front-end based development with <b className="purple"> HTML, CSS, JavaScript, and React. </b> I am also well versed in mobile development with the <b className="purple"> Android flavor of Java </b> and <b className="purple"> React Native. </b>
              <br></br>
              <br></br>
              I am eager to grow my career as a software engineer and to be a part of a phenomenal team. I have always been a quick learner, actualizing concepts and adding my own level of understanding to them, and very observant of the world around me; I plan to use these positive traits to make an impact on my colleagues, my community, and the world we live in.
            </p>
          </Col>

          <Col
            md={5}
            style={{ paddingTop: "0px", paddingBottom: "20px" }}
            className="about-img">
            <img src={lightbulb} alt="about" height={320} />
          </Col>
        </Row>

        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        <Github />
      </Container>
    </Container>
  );
}

export default About;
