const contactConfig = {
  YOUR_EMAIL: 'abelaj50@gmail.com',
  YOUR_PHONE: '(952)-500-0565',
  description:
    'Direct contact information is listed above. Email is strongly preferred. You can expect a response by the next business day if not same day.',
  description2: 'Feel free to reach out to me via email submission!',
  description3: 'Please allow 1-2 business days for a response.',
  YOUR_SERVICE_ID: 'service_id',
  YOUR_TEMPLATE_ID: 'template_id',
  YOUR_USER_ID: 'user_id',
};

 
export { contactConfig };