import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiIntellijidea,
  SiJira,
  SiXcode,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={6} md={3} xl={2} >
        <SiLinux className="tech-iconsPic"/>
        <p>Linux</p>
      </Col>
      <Col xs={6} md={3} xl={2} >
        <SiVisualstudiocode className="tech-iconsPic"/>
        <p>VS Code</p>
      </Col>
      <Col xs={6} md={3} xl={2} >
        <SiIntellijidea className="tech-iconsPic"/>
        <p>IntelliJ IDEA</p>
      </Col>
      <Col xs={6} md={3} xl={2} >
        <SiXcode className="tech-iconsPic"/>
        <p>Xcode</p>
      </Col>
      <Col xs={6} md={3} xl={2} >
        <SiJira className="tech-iconsPic"/>
        <p>Jira</p>
      </Col>
    </Row>
  );
}

export default Toolstack;
