import React, { useRef, useState } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import Particle2 from "../Particle2";
import { contactConfig } from "./content_options"
import emailjs from '@emailjs/browser';

function Contact() {

  const form = useRef();
  const [result, showResult] = useState(false);

  const Result = () => {
    return(
      <p> Your message has been sent. You should expect a response within 1-2 business days.</p>
    );
  };

  // setTimeout(() => {
  //   showResult(false);
  // }, 10000)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9slcq8p', 'template_oznufoj', form.current, 'OBG4JarsBCpjxaYg8')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
          showResult(true);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <Container fluid className="contact-section">
      <div className="particle-style2"> <Particle2 /> </div>
      <Container>

        <Row className="mb-5 mt-3">
          <Col lg="5">
            <h1 className="display-4 mb-4">Contact <strong className="purple">Me </strong></h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>

        <Row className="sec_sp">
          <Col lg="5" className="mb-5">
            <h3 className="color_sec py-4">Let's Connect!</h3>
            {/* <address>
              <strong>Email:</strong>{" "}
              <a href={`mailto:${contactConfig.YOUR_EMAIL}`}>
                {contactConfig.YOUR_EMAIL}
              </a>
              <br />
              <br />
              <strong>Phone: </strong> 
              {contactConfig.hasOwnProperty("YOUR_PHONE") ? (
              <a href={`tel:${contactConfig.YOUR_PHONE}`}> {contactConfig.YOUR_PHONE} </a>) : ( "" )
              }
            </address> */}
            <p>{contactConfig.description2}</p>
            <p>{contactConfig.description3}</p>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <form  ref={form} className="contact__form w-100" onSubmit={sendEmail}>
              <Row>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control"
                    id="from_name"
                    name="from_name"
                    placeholder="Name" 
                    type="text"
                    required 
                  />
                </Col>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control rounded-0"
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email" 
                    required 
                  />
                </Col>
              </Row>
              <textarea
                className="form-control rounded-0"
                id="message"
                name="message"
                placeholder="Message"
                rows="5" 
                required>
              </textarea>
              <br/>
              <Row>
                <Col lg="12" className="form-group">

                  <div>
                    {result ? <Result /> : null}
                  </div>

                  <Button
                    type="submit"
                    style={{ maxWidth: "250px", marginBottom: "70px" }}>
                    Send
                  </Button>

                </Col>
              </Row>
            </form>
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default Contact;
