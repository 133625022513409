import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle2 from "../Particle2";
import stockUp from "../../Assets/Projects/stockUp.png";
import movieClient from "../../Assets/Projects/movieClient.png";
import puzzle15 from "../../Assets/Projects/puzzle15.png";
import brainTemp300 from "../../Assets/Projects/brainTemp300.png";
import abelsAnimals from "../../Assets/Projects/abelsAnimals.png";
import gopherHunting from "../../Assets/Projects/gopherHunting.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <div className="particle-style2"> <Particle2 /> </div>
      <Container style={{marginBottom: '30px'}}>
        <h1 className="project-heading">
          Project <strong className="purple">Highlights </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few of my key projects.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={abelsAnimals}
              isBlog={false}
              title="Abel's Animals"
              description="Android application displaying a number of different animals within a grid and interesting facts about them along with other features."
              ghLink="https://github.com/Abelaj50/Abels-Animals"
              demoLink="https://youtu.be/iwDWsdAIJsk"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={stockUp}
              isBlog={false}
              title="StockUp"
              description="React Native application simulating a fully functional UI for a finance app consisting of a debit card earning stock rewards."
              ghLink="https://github.com/hgavil/StockUp"
              demoLink="https://snack.expo.dev/@abelaj50/8892a2?platform=ios"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={movieClient}
              isBlog={false}
              title="Movie Client"
              description="Android application showcasing various movie trailers and related facts, with information retrieved from a bound service's API."
              ghLink="https://github.com/Abelaj50/Movie-Client"
              demoLink="https://youtu.be/Ese8AHrVqMg"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={puzzle15}
              isBlog={false}
              title="15 Puzzle"
              description="Classic game of 15 Puzzle with threaded AI path finding using the A-Star search algorithm. Creating using Java and JavaFX."
              ghLink="https://github.com/Abelaj50/Threaded-A-Star-15-Puzzle"
              demoLink="https://youtu.be/9GdFLcM8d1k"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={brainTemp300}
              isBlog={false}
              title="Brain Temp 300°"
              description="3D game developed using Unity as part of a group project. Incorporates various elements of physics, AI, shaders, and sound."
              ghLink="https://github.com/elliespearman2022/cs426_Brain_Tamp_300-"
              demoLink="https://www.evl.uic.edu/cs426/finals/2021-Spring/Videos/BrainTemp300.mp4"
            />
           </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gopherHunting}
              isBlog={false}
              title="Gopher Hunting"
              description="Android application and multi-threaded grid based game in which the device plays against itself using a smart algorithm."
              ghLink="https://github.com/Abelaj50/Gopher-Hunting"
              demoLink="https://youtu.be/co_mUXC1WNk"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
