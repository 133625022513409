import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiPython,
  DiGit,
  DiJava,
  DiRuby,
  DiAndroid,
  DiSwift,
} from "react-icons/di";

function Techstack() {
  return (
    <Container >
      <Row style={{ justifyContent: "center", paddingBottom: "60px" }} >
        <Col xs={6} md={3} xl={2} >
          <CgCPlusPlus className="tech-iconsPic"/>
            <p>C++</p>
        </Col>
        <Col xs={6} md={3} xl={2}>
          <DiJava className="tech-iconsPic"/>
            <p>Java</p>
        </Col>
        <Col xs={6} md={3} xl={2}>
          <DiRuby className="tech-iconsPic"/>
          <p>Ruby</p>
        </Col>
        <Col xs={6} md={3} xl={2}>
          <DiAndroid className="tech-iconsPic"/>
          <p>Android</p>
        </Col>
        <Col xs={6}  md={3} xl={2}>
          <DiSwift className="tech-iconsPic"/>
          <p>Swift</p>
        </Col>
        <Col xs={6} md={3} xl={2}>
          <DiJavascript1 className="tech-iconsPic"/>
          <p>Javascript</p>
        </Col>

        <Col xs={6} md={3} xl={2}>
          <DiReact className="tech-iconsPic"/>
          <p>React</p>
        </Col>
        <Col xs={6} md={3} xl={2}>
          <DiNodejs className="tech-iconsPic"/>
          <p>Node.js</p>
        </Col>
        <Col xs={6}  md={3} xl={2}>
          <DiGit className="tech-iconsPic"/>
          <p>Git</p>
        </Col>
        <Col xs={6} md={3} xl={2}>
          <DiPython className="tech-iconsPic"/>
          <p>Python</p>
        </Col>
      </Row>
    </Container>

  );
}

export default Techstack;
