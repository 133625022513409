import React from "react";
import Particles from "react-tsparticles";

function Particle2() {
  return (
    <Particles
    id="tsparticles"

    options={{
      "fullScreen": {
          "enable": false,
          
      },
      "particles": {
          "number": {
              "value": 200,
              "density": {
                  "enable": false,
                  "value_area": 1
              }
          },
          "color": {
              "value": "#fff"
          },
          "shape": {
              "type": "triangle",
              "options": {
                  "sides": 5
              }
          },
          "opacity": {
              "value": 20,
              "random": true,
              "anim": {
                  "enable": false,
                  "speed": 1,
                  "opacity_min": 1,
                  "sync": false
              }
          },
          "size": {
              "value": 6,
              "random": false,
              "anim": {
                  "enable": true,
                  "speed": 5,
                  "size_min": 0.1,
                  "sync": false
              }
          },
          "rotate": {
              "value": 0,
              "random": true,
              "direction": "clockwise",
              "animation": {
                  "enable": true,
                  "speed": 5,
                  "sync": false
              }
          },
          "line_linked": {
              "enable": false,
              "distance": 500,
              "color": "#ffffff",
              "opacity": 0.3,
              "width": 1
          },
          "move": {
              "enable": true,
              "speed": 0.1,
              "direction": "none",
              "random": true,
              "straight": false,
              "out_mode": "out",
              "attract": {
                  "enable": false,
                  "rotateX": 600,
                  "rotateY": 1200
              }
          }
      },
      "interactivity": {
          "events": {
              "onhover": {
                  "enable": false,
                  "mode": ["grab"]
              },
              "onclick": {
                  "enable": false,
                  "mode": "bubble"
              },
              "resize": true
          },
          "modes": {
              "grab": {
                  "distance": 400,
                  "line_linked": {
                      "opacity": 1
                  }
              },
              "bubble": {
                  "distance": 400,
                  "size": 40,
                  "duration": 2,
                  "opacity": 8,
                  "speed": 3
              },
              "repulse": {
                  "distance": 200
              },
              "push": {
                  "particles_nb": 4
              },
              "remove": {
                  "particles_nb": 2
              }
          }
      },
      "retina_detect": true,
      "background": {
          // "color": "#111",
          // "image": "",
          // "position": "50% 50%",
          // "repeat": "no-repeat",
          // "size": "cover"
      }
  }}
  />
  );
}

export default Particle2;
