import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar-min.png";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              QUICK <span className="purple"> BIO </span>
            </h1>
            <p className="home-about-body">
              I have been fascinated with technology for as long as I can remember. 
              <br />
              <br />
              From tinkering around with our old Windows 95 machine as a child, to creating 
              <i>
                <b className="purple"> cross-platform   mobile banking applications </b>
              </i>
               and 
              <i>
                <b className="purple"> client-server model based software, </b>
              </i>
              I am excited to step into the professional world after completing 
              my studies at the <a href="https://www.uic.edu/" target="_blank" rel="noreferrer" className="purple"> University of Illinois Chicago. </a>
              <br />
              <br />

              I have interests in various fields within Computer Science, 
              mostly tied to both 
              <i> <b className="purple"> front-end </b> </i> 
              and 
              <i> <b className="purple"> back-end development, </b> </i> 
              but also in UI/UX
              and
              subfields of Artifial Intelligence, such as 
              <i> <b className="purple"> Natural Language Processing </b> </i> 
              and 
              <i> <b className="purple"> Neural Networks. </b> </i> 
              

              <br />
              <br />
              Outside of development, I enjoy listening to music and traveling.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <img src={myImg} className="img-fluid" alt="avatar" style={{maxHeight: "350px"}} />
          </Col>
        </Row>
        <br></br>
        <br></br>
        <Row>
          <Col md={12} className="home-about-social">
            <i style={{ fontSize: "24px", color: "rgb(155 126 172)" }}>
            "You can’t connect the dots looking forward; you can only connect them looking backwards."
          </i>
          <br></br>
          <i style={{ fontSize: "24px", color: "rgb(155 126 172)"}}>
            -Steve Jobs
          </i>
          <br></br>
          <br></br>

          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
